import { ExperiencePerformanceTypes, ExperienceTypes } from '@atlassian/ufo';

import { TeamsUFOExperience } from './index';

const TeamsClientExperienceKeyList = [
	'getAllTeams',
	'getTeamById',
	'updateTeamById',
	'querySettings',
	'queryFeatureFlags',
	'queryTeamMemberships',
	'queryUser',
	'inviteUsersToTeam',
	'getMyTeamMembership',
	'getTeamLinksByTeamId',
	'createTeamLink',
	'updateTeamLink',
	'deleteTeamLink',
	'reorderTeamLink',
	'acceptTeamInvitation',
	'declineTeamInvitation',
	'selfJoinTeam',
	'cancelJoinRequest',
	'acceptJoinRequest',
	'declineJoinRequest',
	'deleteTeamMembership',
	'revokeTeamInvite',
	'deleteTeam',
	'isSingleFullMemberTeam',
	'createTeam',
	'createExternalTeam',
	'getProfileWithMutability',
	'getIsSiteAdmin',
	'addUsersToTeam',
	'removeTeamMemberships',
	'getRecommendedProducts',
	'joinOrRequestDefaultAccessToProductsBulk',
	'getReadMediaToken',
	'getWriteUserMediaToken',
	'updateUserHeaderImage',
	'getWriteTeamMediaToken',
	'getSoftDeletedTeamById',
	'restoreTeam',
	'getUserInSiteUserBase',
	'getLinkedTeamsBulk',
	'getTeamProfileDetails',
	'getUserContainers',
	'getPermissions',
	'getUserManageConfig',
	'getMyAvatarUploadedStatus',
	'getTeamLinkIcons',
	'getTeamInSlack',
	'getOrgScope',
	'restoreTeamToSyncedGroup',
	'getTeamAndGroupDiff',
	'linkExistingTeamToGroup',
] as const;
export type TeamsClientExperienceKeys = (typeof TeamsClientExperienceKeyList)[number];

export const TEAMS_CLIENT_EXPERIENCES = new Map<TeamsClientExperienceKeys, TeamsUFOExperience>([
	...TeamsClientExperienceKeyList.map<[TeamsClientExperienceKeys, TeamsUFOExperience]>((key) => [
		key,
		// camelCase to kebab-case
		new TeamsUFOExperience(
			`teams-client-${key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()}`,
			{
				type: ExperienceTypes.Operation,
				performanceType: ExperiencePerformanceTypes.Custom,
				platform: { component: 'people' },
			},
		),
	]),
]);
